import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import BroadwayEcclesImage from '../../assets/images/publication-banners/broadway_eccles.jpg';

export const query = graphql`
  query BaeQuery {
    allBaeCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function BroadwayEccles({ data }) {
  const baeNodes = data.allBaeCsv.nodes;
  // console.log(balletNodes);

  const imageRef = '/season-images/broadway-at-the-eccles/2024/';
  // append the image reference with the path to the image via the static folder

  return (
    <Layout>
      <Helmet>
        <title>Broadway at the Eccles - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Broadway at the Eccles’ season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Broadway at the Eccles</h1>
            </header>
            <span className="image main">
              <img src={BroadwayEcclesImage} alt="" />
            </span>
            <p className="box">
              Broadway at the Eccles boasts our highest numbers every season by
              bringing in the newest hits. Each season, between 200,000—300,000
              playbills are distributed to ticket holders. Take advantage of
              this broad and ever-growing market to elevate your brand.
            </p>
            <ul className="actions">
              <li>
                <a
                  href="https://www.broadway-at-the-eccles.com/"
                  className="button special"
                >
                  <i className="icon fa-globe"> </i>Broadway at the Eccles
                  Website
                </a>
              </li>
            </ul>
            <h2>
              Broadway at the Eccles <SeasonYear /> Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the ecclesSeason array, and maps/creates a new array */}
              {baeNodes.map((node) => (
                <div className="col-4 box" key={node.id}>
                  <img
                    style={{ width: 100 + '%' }}
                    src={imageRef + node.Image}
                  />
                  <h3>{node.SHOW}</h3>
                  <strong>{node.RUNNING_DATES}</strong> <br />
                  <br />
                  <h5>Advertising Information</h5>
                  <section className="box">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            File Ready Deadline: <br />
                            {node.FR}
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Advertising Exposure: {node.exposure}</td>
                        </tr> */}
                        <tr>
                          <td>Print Quantity: {node.Quantity}</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
